import Link from 'next/link';

interface Props {
  author: string;
}

export default function Author({ author }: Props) {
  return (
    <Link href={`https://platzi.com/p/${author}`} passHref>
      <a target="_blank" rel="noopener noreferrer">
        {author}
      </a>
    </Link>
  );
}
