import { useState, useEffect } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getLocalSource } from '@utils/local';
import getQuizOfDay from '@utils/getQuizOfDay';
import LayoutPage from '@components/LayoutPage';
import Layout from '@components/Layout';
import PostView from '@components/PostView';
import confetti from 'canvas-confetti';

import { Post } from '../models/post';
import { logEvent } from '@utils/logEvent';

const Answers = dynamic(() => import('@components/Answers'));
const AlertError = dynamic(() => import('@components/AlertError'));
const AlertSuccess = dynamic(() => import('@components/AlertSuccess'));
const Explanation = dynamic(() => import('@components/Explanation'));
const Course = dynamic(() => import('@components/Course'));

export default function Home({ posts }: { posts: Post[] }) {
  const [answer, setAnswer] = useState(true);
  const [wrongAnswer, setWrongAnswer] = useState(false);
  const { quiz, tomorrow } = getQuizOfDay();
  const { response, explanation, answers, course, reference } = posts[quiz];

  const handleConfetti = () => {
    confetti({
      zIndex: 999,
      particleCount: 200,
      spread: 90,
      origin: { x: 0.5, y: 0.6 },
    });
  };

  const handleSuccess = () => {
    setAnswer(false);
    handleConfetti();
  };

  const handleError = () => {
    setAnswer(false);
    setWrongAnswer(true);
  };

  const handleAnswer = (id: number) => {
    localStorage.setItem('nextDate', tomorrow.toString());
    if (id === response) {
      handleSuccess();
      localStorage.setItem('correctAnswer', 'true');
      logEvent('quiz', 'handleAnswer', 'correct', quiz);
    } else {
      handleError();
      localStorage.setItem('wrongAnswer', 'true');
      logEvent('quiz', 'handleAnswer', 'wrong', quiz);
    }
  };

  useEffect(() => {
    const nextDate = parseInt(localStorage.getItem('nextDate'));
    if (nextDate !== tomorrow) {
      localStorage.removeItem('correctAnswer');
      localStorage.removeItem('wrongAnswer');
    }
    if (localStorage.getItem('correctAnswer')) handleSuccess();
    if (localStorage.getItem('wrongAnswer')) handleError();
  });

  return (
    <>
      <Head>
        <title>JavaScript Hero | Un Desafío diario de JavaScript</title>
      </Head>

      <LayoutPage>
        <PostView {...posts[quiz]} />
        <Layout>
          {answer ? (
            <>
              <Course course={course} link={reference} />
              <Answers data={answers} handleClick={handleAnswer} />
            </>
          ) : (
            <>
              {wrongAnswer ? <AlertError date={tomorrow} /> : <AlertSuccess date={tomorrow} />}
              <Explanation explanation={explanation} response={response} answers={answers} />
              <Course course={course} link={reference} />
            </>
          )}
        </Layout>
      </LayoutPage>
    </>
  );
}

export async function getStaticProps({ locale }) {
  const source = getLocalSource();
  const posts = await source.getAllPosts();

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      posts,
    },
  };
}
