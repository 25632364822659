import Header from '@components/Header';
interface Props {
  children: JSX.Element | JSX.Element[];
}

export default function LayoutPage({ children }: Props) {
  return (
    <>
      <Header />
      <div className="flex-block max-w-6xl mx-auto p-4 md:pd-8">{children}</div>
    </>
  );
}
