import { useTranslation } from 'next-i18next';
import Link from 'next/link';

export default function Nav() {
  const { t } = useTranslation();

  return (
    <>
      <nav className="w-full flex items-center w-auto block pt-0" id="nav-content">
        <ul className="list-reset lg:flex justify-end flex-1 items-center">
          <li>
            <span className="relative inline-flex mr-4">
              <Link href="/challenges">
                <a
                  type="button"
                  className="inline-flex items-center px-2 py-1 md:px-4 md:py-2 font-semibold leading-6 text-sm shadow rounded-md text-sky-500 bg-slate-800 transition ease-in-out duration-150 cursor-pointer ring-1 ring-slate-200/20"
                >
                  {t('nav.challenges')}
                </a>
              </Link>
              <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
              </span>
            </span>
          </li>
        </ul>
      </nav>
    </>
  );
}
