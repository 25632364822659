const getQuizOfDay = () => {
  const epochMs = new Date('June 4, 2024 00:00:00').valueOf();
  const now = new Date();
  const msInDay = 86400000;
  const index = Math.floor((now - epochMs) / msInDay);
  const nextday = (index + 1) * msInDay + epochMs;
  const nowFormat = index * msInDay + epochMs;
  return {
    now: nowFormat,
    quiz: index,
    tomorrow: nextday,
  };
};

export default getQuizOfDay;
