import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import Image from 'next/image';
import Nav from './Nav';
const logo = '/platzi_logo.png';

export default function Header() {
  const { t } = useTranslation('common');

  return (
    <header className="shadow-lg">
      <div className="flex items-center justify-between flex-wrap max-w-6xl mx-auto bg-platzi-dark py-4 px-2 md:px-6 ">
        <div className="flex items-center flex-shrink-0">
          <span className="text-white no-underline hover:text-white hover:no-underline">
            <span className="flex text-xl ml-2 mb-6 sm:mb-0">
              <div className="w-[24px] h-[28px] md:w-[28px] md:h-[32px] mr-2">
                <Image layout="intrinsic" height={100} width={100} src={logo} alt="Platzi Logo" />
              </div>
              <div className="flex-block">
                <Link href="/" passHref>
                  <a>
                    <h1 className="font-medium text-xl sm:text-2xl text-white cursor-pointer">
                      {t('header.title')}
                    </h1>
                  </a>
                </Link>
              </div>
            </span>
          </span>
        </div>
        <Nav />
      </div>
    </header>
  );
}
