import React from 'react';
import { useTranslation } from 'next-i18next';
import Markdown from 'markdown-to-jsx';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { shadesOfPurple } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { Post } from '@models/post';
import MarkdownImage from './Markdown/MarkdownImage';
import Author from './Author';

export default function PostView({ title, content, level, author, random }: Post) {
  const { t } = useTranslation();

  return (
    <article>
      {random && (
        <span
          className="inline-block rounded-sm text-platzi-blue
                bg-platzi-dark-second hover:text-white duration-300
                text-xs font-bold
                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1
                opacity-90 hover:opacity-100"
        >
          {t('postView.random')}
        </span>
      )}
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-bold inline-block">{title}</h2>
        <div className="text-right">
          <span
            className="inline-block rounded-sm text-platzi-green
                bg-platzi-dark-second  duration-300
                text-xs font-bold capitalize
                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1
                opacity-90 hover:opacity-100 cursor-pointer"
          >
            {level}
          </span>
          <span
            className="inline-block rounded-sm platzi-white-2
                bg-platzi-dark-second hover:text-white duration-300
                text-xs font-bold
                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1
                opacity-90 hover:opacity-100"
          >
            <Author author={author} />
          </span>
        </div>
      </div>
      <div className="prose lg:prose-lg mx-auto max-w-6xl">
        <Markdown
          options={{
            overrides: {
              img: {
                component: MarkdownImage,
              },
              p: {
                component: ({ children, ...props }) => {
                  const ParaComponent = children[0]?.type === MarkdownImage ? 'div' : 'p';
                  return <ParaComponent {...props}>{children}</ParaComponent>;
                },
              },
              pre: {
                component: ({ children, ...props }) => <p {...props}>{children}</p>,
              },
              code: {
                component: ({ className, children }) => {
                  const language = (className || '').replace('lang-', '');
                  return (
                    <SyntaxHighlighter language={language} style={shadesOfPurple}>
                      {children}
                    </SyntaxHighlighter>
                  );
                },
              },
            },
          }}
        >
          {content}
        </Markdown>
      </div>
    </article>
  );
}
